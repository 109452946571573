// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"src/providers/channel/provider.ts"
);
import.meta.hot.lastModified = "1730916919166.2097";
}
// REMIX HMR END

import {
  ChannelQuery,
  ChannelsQuery,
  ListCurrencyQuery,
  ListSellersQuery,
  ListCountriesQuery,
  ListLanguagesQuery,
  ListZonesQuery,
  ListAvailableLanguagesQuery,
  ChannelListOptions,
  CreateChannelInput,
  UpdateChannelInput,
  AddChannelMutation,
  UpdateChannelMutation,
  DeleteChannelMutation,
  AvailableChannelsQuery,
  ActiveChannelQuery,
} from '~admin/generated/graphql'
import { QueryOptions, sdk } from '~admin/graphqlWrapper'

export const activeChannel = (
  queryOptions?: QueryOptions,
): Promise<ActiveChannelQuery> => {
  return sdk.activeChannel(undefined, queryOptions)
}

export const listCurrenciesEnum = (
  options: undefined,
  queryOptions?: QueryOptions,
): Promise<ListCurrencyQuery> => {
  return sdk.listCurrency({}, queryOptions)
}

export const listAvailableChannels = (
  options?: ChannelListOptions,
  queryOptions?: QueryOptions,
): Promise<AvailableChannelsQuery> => {
  return sdk.availableChannels({ options }, queryOptions)
}

export const list = (
  options?: ChannelListOptions,
  queryOptions?: QueryOptions,
): Promise<ChannelsQuery> => {
  return sdk.channels({ options }, queryOptions)
}

export const listSellers = (
  options?: undefined,
  queryOptions?: QueryOptions,
): Promise<ListSellersQuery> => {
  return sdk.listSellers({}, queryOptions)
}

export const listCountries = (
  options?: undefined,
  queryOptions?: QueryOptions,
): Promise<ListCountriesQuery> => {
  return sdk.listCountries({}, queryOptions)
}

export const listZones = (
  options?: undefined,
  queryOptions?: QueryOptions,
): Promise<ListZonesQuery> => {
  return sdk.listZones({}, queryOptions)
}

export const listLanguages = (
  options?: undefined,
  queryOptions?: QueryOptions,
): Promise<ListLanguagesQuery> => {
  return sdk.listLanguages({}, queryOptions)
}

export const listAvailableLanguages = (
  options?: undefined,
  queryOptions?: QueryOptions,
): Promise<ListAvailableLanguagesQuery> => {
  return sdk.listAvailableLanguages({}, queryOptions)
}

export const get = (
  id: string,
  options?: QueryOptions,
): Promise<ChannelQuery> => {
  return sdk.channel({ id }, options)
}

export const add = (
  input: CreateChannelInput,
  options?: QueryOptions,
): Promise<AddChannelMutation> => {
  return sdk.addChannel({ input }, options)
}

export const update = (
  input: UpdateChannelInput,
  options?: QueryOptions,
): Promise<UpdateChannelMutation> => {
  return sdk.updateChannel({ input }, options)
}

export const remove = (
  id: string,
  options?: QueryOptions,
): Promise<DeleteChannelMutation> => {
  return sdk.deleteChannel({ id }, options)
}
